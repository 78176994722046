import { ListOl, Search, ThreeDots } from 'react-bootstrap-icons';
import React, { useState } from 'react';
import { Outline } from 'react-pdf';

function PDFOutlet(props) {
    const { setPageNumber, searchText, setSearchText, searchIndex } = props

    const [showOutlet, setShowOutlet] = useState('hide')
    const [outletMode, setOutletMode] = useState('toc')

    const toggleOutlet = () => {
        if (!showOutlet)
            setShowOutlet('hide')
        else
            setShowOutlet('')
    }

    const toggleOutletContent = (newContent) => {
        setShowOutlet('')
        setOutletMode(newContent)
    }

    function onItemClick({ pageNumber }) {
        setPageNumber(pageNumber);
    }

    const filter = (page) => {
        let a = page.items.toUpperCase()
        let b = searchText.toUpperCase()
        let includes = a.includes(b)

        return includes
    }

    let searchResults
    if (searchIndex != null)
        searchResults = (
            searchIndex.filter(filter)
        )

    let outletContent
    outletContent = (outletMode === 'toc') ? (
        < Outline onItemClick={onItemClick} />
    ) : (
        <>
            <input className='input-searchText' autoFocus type='text' value={searchText} onChange={(e) => setSearchText(e.target.value)}></input>
            <div>
                {
                    (searchText.length > 2)
                        ? (
                            (searchResults.length !== 0)
                                ? (
                                    searchResults
                                        .map(page => {
                                            return (
                                                <h1 className='searchResult' onClick={() => setPageNumber(Number(page.pageNumber))}>{page.pageNumber}</h1>
                                            )
                                        })
                                )
                                : (
                                    <p className='searchResult-deafult'>Keine Ergebnisse gefunden.</p>
                                )
                        )
                        : (
                            <p className='searchResult-deafult'>Geben Sie mehr als 2 Zeichen ein, um die Suche zu starten.</p>
                        )
                }
            </div >
        </>
    )

    return (
        <div className='side-nav-bar'>
            <div className='side-nav-bar-icons'>
                <div title='Menü Schließen'>
                    <button onClick={toggleOutlet}>
                        <ThreeDots className='side-nav-bar-icon' />
                    </button>
                </div>
                <div title='Inhaltsverzeichnis'>
                    <button onClick={() => toggleOutletContent('toc')}>
                        <ListOl className='side-nav-bar-icon' />
                    </button>
                </div>
                <div title='Dokument durchsuchen'>
                    <button onClick={() => toggleOutletContent('search')}>
                        <Search className='side-nav-bar-icon' />
                    </button>
                </div>
            </div>

            <div className={`side-nav-bar-content ${(showOutlet)}`}>
                <div className='side-nav-bar-content-tableOfContent'>
                    {outletContent}
                </div>
            </div>
        </div>
    )
}
export default PDFOutlet