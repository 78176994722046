import { useState } from "react";

function Neuigkeiten() {
    const [data, setData] = useState([])

    // function getVideoCardInfo() {
    //     const gl = document.createElement('canvas').getContext('webgl');
    //     if (!gl) {
    //         return {
    //             error: "no webgl",
    //         };
    //     }
    //     const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    //     return debugInfo ? {
    //         vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
    //         renderer: gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
    //     } : {
    //         error: "no WEBGL_debug_renderer_info",
    //     };
    // }

    setTimeout(() => {
        let _data = []

        // _data.push(navigator.cookieEnabled.toString())
        _data.push(navigator.language)
        _data.push(navigator.userAgent)

        _data.push(window.screen.width + "x" + window.screen.height + " / " + window.screen.availWidth + "x" + window.screen.availHeight + " / " + window.screen.colorDepth)
        _data.push(navigator.hardwareConcurrency)
        // _data.push(getVideoCardInfo().renderer)
        // _data.push(getVideoCardInfo().vendor)

        setData(_data)
    }, 1000);

    const displayData = data.map(_data => <h4>{_data}</h4>)

    return (
        <div>
            <div className='header-area'>
                <h1 className='heading'>ÖBV Neuigkeiten</h1>
                <br />
                <br />
                <br />
                <p>{displayData}</p>
            </div>
        </div>
    );
}
export default Neuigkeiten