import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';
import DoublePage from './DoublePage';
import SinlgePage from './SinlgePage';
import { Document, pdfjs } from 'react-pdf';
import '../Publikation.css'
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PDFOutlet from './PDFOutlet';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PDFDocument(props) {
    const { fileContent, searchIndex, showOutlet } = props
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState('')

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offSet) {
        setPageNumber(pageNumber + offSet);
    }

    function changePageBack() {
        if (pageNumber === 1)
            return

        if (pageNumber === 2)
            changePage(-1)
        else
            changePage(-2)
    }

    function changePageNext() {
        if (pageNumber === numPages)
            return

        if (pageNumber === 1 || pageNumber === numPages - 1)
            changePage(1)
        else
            changePage(2)
    }

    let btnPageBackDisabled = (pageNumber === 1) ? true : false

    const updatePageNumber = (e) => {
        let newValue = Number(e.target.value)

        if (newValue < 0)
            newValue = 1

        if (newValue > numPages)
            newValue = numPages

        setPageNumber(newValue)
    }

    let pageContent = (pageNumber === numPages || pageNumber === 1) ? (
        <Row>
            <SinlgePage
                pageNumber={pageNumber}
                searchText={searchText} />
        </Row>
    ) : (
        <Row>
            <DoublePage
                pageNumber={pageNumber}
                searchText={searchText} />
        </Row>
    )

    let outletContent
    if (showOutlet) {
        outletContent = (
            <PDFOutlet
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                searchText={searchText}
                setSearchText={setSearchText}
                searchIndex={searchIndex}
            />
        )
    }

    let pageNavigation
    pageNavigation = (
        <Row>
            <Col className='center-text'>
                <h2 className='page-number'>
                    <button onClick={changePageBack} disabled={btnPageBackDisabled}>
                        <CaretLeftFill className='page-navigator' />
                    </button>
                    Seite
                    <input className='input-page-number'
                        type='text'
                        value={pageNumber}
                        onChange={updatePageNumber} />
                    von
                    <span className='input-page-number'>
                        {numPages}
                    </span>
                    <button onClick={changePageNext} disabled={false}>
                        <CaretRightFill className='page-navigator' />
                    </button>
                </h2>
            </Col>
        </Row>
    )

    const test = (e) => {
        if (e.deltaY < 0) {
            changePageBack()
        } else if (e.deltaY > 0) {
            changePageNext()
        }
    }

    function onItemClick({ pageNumber }) {
        if (pageNumber % 2 === 1)
            pageNumber -= 1

        setPageNumber(pageNumber)
    }

    return (
        <Document file={`data:application/pdf;base64,${fileContent}`}
            onLoadSuccess={onDocumentLoadSuccess}
            className='test'
            onWheel={test}
            onItemClick={onItemClick}
        >
            <Container className='margin'>
                {pageContent}
                {pageNavigation}
            </Container>
            {outletContent}
        </Document >
    )
}
export default PDFDocument