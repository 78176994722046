import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

function Register() {
    const [firmenID, setFirmenID] = useState('250dfc56-fa76-4b8a-9691-c712ac763ae3')
    const [username, setUsername] = useState('nadolph@bautechnik.pro')
    const [validateUsername, setValidateUsername] = useState('nadolph@bautechnik.pro')
    const [password, setPassword] = useState('Test123!')

    const [firmendIDError, setFirmenIDError] = useState('')
    const [usernameError, setUsernameError] = useState('')
    const [validateUsernameError, setValidateUsernameError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [valid, setValid] = useState(true)
    const navigate = useNavigate();

    const onChangeUsername = (e) => {
        setUsername(e.target.value)
        validateInputs(firmenID, e.target.value, validateUsername, password)
    }

    const onChangeValidateUsername = (e) => {
        setValidateUsername(e.target.value)
        validateInputs(firmenID, username, e.target.value, password)
    }

    const onChangeFirmendID = (e) => {
        setFirmenID(e.target.value)
        validateInputs(e.target.value, username, validateUsername, password)
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
        validateInputs(firmenID, username, validateUsername, e.target.value)
    }

    const validateInputs = (firmenID, username, validateUsername, password) => {
        setValid(false)

        if (!firmenID)
            setFirmenIDError('Sie müssen eine Firmen-ID eingeben!')
        else
            setFirmenIDError('')

        if (!username || !ValidateEmail(username)) {
            setUsernameError('Geben Sie eine gültige E-Mail-Adresse ein!')
            return
        }
        setUsernameError('')

        if (username !== validateUsername) {
            setValidateUsernameError('E-Mails müssen übereinstimmen!')
            return
        }
        setValidateUsernameError('')

        if (!ValidatePassword(password) || !password) {
            setPasswordError('Passwort muss mindestens 8 Zeichen haben, davon zumindest ein Klein-, ein Großbuchstabe und eine Ziffer!')
            return
        }
        setPasswordError('')
        setValid(true)
    }

    function ValidateEmail(_user) {
        // eslint-disable-next-line no-useless-escape
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_user)
    }

    function ValidatePassword(_password) {
        // eslint-disable-next-line no-useless-escape
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

        return strongRegex.test(_password)
    }

    const getFirmenIDError = () => {
        if (firmendIDError)
            return (
                <span className='login-error'>{firmendIDError}</span>
            )
    }

    const getUserNameError = () => {
        if (usernameError)
            return (
                <span className='login-error'>{usernameError}</span>
            )
    }

    const getValidateUserNameError = () => {
        if (validateUsernameError)
            return (
                <span className='login-error'>{validateUsernameError}</span>
            )
    }

    const getPasswordError = () => {
        if (passwordError)
            return (
                <span className='login-error'>{passwordError}</span>
            )
    }

    const register = async () => {
        setValid(false)
        try {
            let response = await axios.post
                (
                    process.env.REACT_APP_BACKEND_URL + '/auth/register',
                    {
                        firmendID: firmenID,
                        username: username,
                        password: password
                    },
                    {
                        withCredentials: true
                    }
                )
            navigate(`/bestaetigen/${response.data.user.ID}`)
            toast.info(response.data.message)
        } catch (err) {
            toast.error(err.response.data.message)
            setValid(true)
        }
    }

    return (
        <div className='login-form'>
            <div className="form-group mt-3">
                <label className='login-label'>Firmen-ID (woher?)</label>
                {getFirmenIDError()}
                <input
                    type="text"
                    value={firmenID}
                    onChange={onChangeFirmendID}
                    className="form-control mt-1"
                />
            </div>
            <div className="form-group mt-3">
                <label className='login-label'>E-Mail (persönlich)</label>
                {getUserNameError()}
                <input
                    type="email"
                    value={username}
                    onChange={onChangeUsername}
                    className="form-control mt-1"
                />
            </div>
            <div className="form-group mt-3">
                <label className='login-label'>E-Mail bestätigen</label>
                {getValidateUserNameError()}
                <input
                    type="email"
                    value={validateUsername}
                    onChange={onChangeValidateUsername}
                    className="form-control mt-1"
                />
            </div>
            <div className="form-group mt-3">
                <label className='login-label'>Passwort</label>
                {getPasswordError()}
                <input
                    type="text"
                    value={password}
                    onChange={onChangePassword}
                    className="form-control mt-1"
                />
            </div>
            <div className="d-grid gap-2 mt-3">
                <button disabled={!valid} className="btn btn-primary login-btn" onClick={() => register()} >
                    Registrieren
                </button>
            </div>
            <p className="forgot-password text-right mt-2">
                Sie haben bereits einen Account? <Link to={'/login'}>Anmelden</Link>
            </p>
        </div>
    )
}
export default Register