import { Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import axios from "axios";
import { toast } from "react-toastify";

function VerifyMsg() {
    const { id } = useParams();

    const [timerS, setTimerS] = useState(4)
    const [timerM, setTimerM] = useState(0)
    const [timerOver, setTimerOver] = useState(false)
    const [mail, setMail] = useState('')

    const asyncUseEffect = async () => {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/auth/' + id)
        setMail(response.data.user.User)
    }

    const resend2faktor = async () => {
        let response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/resend2faktor/' + id)
        toast.info(response.data.message)
    }

    useEffect(() => {
        if (mail === '') {
            asyncUseEffect()
        }
    })

    function zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    const updateTimer = () => {
        if (timerS === 0 && timerM === 0)
            setTimerOver(true)
        else if (timerS === 0) {
            setTimerS(59)
            setTimerM(timerM - 1)
        } else
            setTimerS(timerS - 1)
    }

    if (!timerOver) {
        setTimeout(() => {
            updateTimer()
        }, 1000);
    }

    let timerOverDiv
    if (timerOver) {
        timerOverDiv = (
            <div className="timer-over">
                <h2>Ihre Zeit ist abgelaufen.</h2>
                <br />
                <h2><Link onClick={() => { resend2faktor() }}>Bestätigungsmail erneut senden</Link></h2>
                <br />
                <h2>Bei fragen <Link to={'/kontakt'}>Kontakt</Link></h2>
            </div>
        )
    }

    return (
        <>
            <div className={(timerOver) ? 'background' : ''}>
                <div className='header-area'>
                    <h1 className='heading'>Ihre E-Mail bestätigen</h1>
                </div>
                <br />
                <div className='container'>
                    <h3>Wir nutzten eine 2-Faktor-Authentifizierung.</h3>
                    <h3>Sie erhalten in kürze eine Mail an <strong>{mail}</strong> mit einen Link zur Bestätigung Ihrer Identität.</h3>
                    <h3>Der Link ist 15 Minuten gültig.</h3>
                </div>
                <div className='timer'>
                    <h1 className='timer-heading'>{zeroPad(timerM, 2)}:{zeroPad(timerS, 2)}</h1>
                </div>
            </div>
            {timerOverDiv}
        </>
    )
}
export default VerifyMsg