module.exports = [
    {
        value: '',
        option: 'Alle Kategorien'
    },
    {
        value: '6fb6526d-2220-42d4-9d1f-9525bfb86f48',
        option: 'Materialtechnologie'
    },
    {
        value: '851a72fc-4a15-4bd1-91c3-0cb93dc4bf94',
        option: 'Baukonstruktion'
    },
    {
        value: 'c003a1a6-c00a-4b82-8faa-d850e5f212cf',
        option: 'Bauverfahren'
    },
    {
        value: '372c1f8b-26e6-4d36-881a-def609740b03',
        option: 'Brückenbau'
    },
    {
        value: '5f38be81-8857-4a89-af64-59f88965d06d',
        option: 'Hochbau'
    },
    {
        value: '1a8e4da8-da4c-47c0-85af-1db939fad513',
        option: 'Infrastruktur'
    },
    {
        value: 'b8233f7e-9008-4c1b-99ca-ded5bebc581f',
        option: 'Straßenbau'
    },
    {
        value: 'a2399d59-76f5-457e-accf-e7ad149d4556',
        option: 'Tunnelbau'
    },
    {
        value: '52265a60-bb00-420c-a3fd-970bc6635062',
        option: 'Gründungstechnik'
    },
    {
        value: 'd75eb200-9a45-409a-acd7-7a44df91cdcb',
        option: 'Instandsetzung'
    },
    {
        value: '4799dca6-a1a2-440e-8c4d-228317972e34',
        option: 'Bauwirtschaft'
    },
    {
        value: '06d50429-1002-4f63-9b6e-2c3aeb1e6472',
        option: 'Digitalisierung'
    },
    {
        value: '4c5a7c36-1a78-4256-9315-f23fdac4379f',
        option: 'Hefte'
    },
    {
        value: '9eeace7a-f766-4f09-a54d-dd37dcb13214',
        option: 'Weißdrucke (außer Kraft)'
    },
    {
        value: '9e390e85-b1ce-461b-903e-465583f25490',
        option: 'Gründrucke (außer Kraft)'
    },
]