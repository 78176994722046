import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import { toast } from 'react-toastify';

function Verify() {
    const { id } = useParams();
    const [errorMsg, setErrorMsg] = useState('')

    const asyncUseEffect = async () => {
        try {
            let url = process.env.REACT_APP_BACKEND_URL + '/auth/verify/' + id

            let response = await axios.post(url)
            toast.info(response.data.message)
            setErrorMsg(null)
        } catch (err) {
            setErrorMsg(err.response.data.message)
        }
    }
    /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        asyncUseEffect()
    }, []);

    let content = (errorMsg === null) ? (
        <div className='container'>
            <h1>Ihre E-Mail wurde erfolgreich bestätigt!</h1>
            <br></br>
            <h1>Sie können sie jetz <Link to={'/login'}>anmelden</Link>!</h1>
        </div>
    ) : (
        <div className='container'>
            <h1>{errorMsg}</h1>
        </div>
    )

    return content
}

export default Verify