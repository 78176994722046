import { Outlet } from 'react-router-dom'
import Header from './Header'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <ToastContainer
                autoClose={2500}
            />
        </>
    )
}
export default Layout