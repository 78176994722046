import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import obvLogo from '../logo_obv.svg'
import { List } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';

function Header() {
    const [user, setUser] = useState({})

    useEffect(() => {
        let _user = localStorage.getItem('user')
        if (_user)
            setUser(JSON.parse(_user))
    }, [])

    const logout = () => {
        localStorage.removeItem('user')
        setUser({})
        window.location.reload()
        // toast.info('Sie wurden erfolgreich abgemeldet.')
    }

    let loginInformation
    if (user?.hasOwnProperty('User')) {
        loginInformation = (
            <>
                <span className='login-msg not-mobile'>Angemeldet als <strong>{user.User}</strong></span>

                <Button onClick={logout} className='not-mobile login-btn' variant="primary">
                    Abmelden
                </Button>
            </>
        )
    } else {
        loginInformation = (
            <>
                <Link to={'/login'} >
                    <Button className='not-mobile login-btn' variant="primary">
                        Anmelden
                    </Button>
                </Link>
            </>

        )
    }
    const getAuthComponent = () => {
        if (user?.User)
            return (
                <>
                    <NavDropdown.Item variant='success'>
                        {user.User.substring(0, 15)}...
                    </NavDropdown.Item>
                    <NavDropdown.Item variant='success'>
                        <span className='link-formated' onClick={logout}>
                            Abmelden
                        </span>
                    </NavDropdown.Item >
                </>
            )
        else
            return (
                // <Link to={'/login'} className='link-formated' variant='success' >
                //     Anmelden
                //     <NavDropdown.Item variant='success' className='link-formated'>

                //     </NavDropdown.Item >
                // </Link >

                <NavDropdown.Item href="/login" className='link-formated'>
                    Anmelden
                </NavDropdown.Item>

            )
    }

    const getNavItemKaufenMobile = () => {
        if (!user?.User)
            return (
                <NavDropdown.Item href={'https://www.bautechnik.pro/Vereinigung/mitglieder_abo'}>
                    <strong>Abo beziehen</strong>
                </NavDropdown.Item>
            )
        // return (
        //     <NavDropdown.Item href="/">
        //         <Link className='link' to={'https://www.bautechnik.pro/Vereinigung/mitglied_werden'} target='_blank'>
        //             <strong>Abo beziehen</strong>
        //         </Link>
        //     </NavDropdown.Item>
        // )
    }

    let dropDownMobile = (
        <NavDropdown title={<List />} id="basic-nav-dropdown" className='mobile menu-icon'>
            <NavDropdown.Item href="/publikationen">
                ÖBV Publikationen
            </NavDropdown.Item>
            <NavDropdown.Item href="/">
                Neuigkeiten
            </NavDropdown.Item>
            {getNavItemKaufenMobile()}
            <NavDropdown.Divider />
            {getAuthComponent()}
        </NavDropdown>
    )

    const getNavItemKaufen = () => {
        if (!user?.User)
            return (
                <Nav.Link className='not-mobile' href={'https://www.bautechnik.pro/Vereinigung/mitglieder_abo'} target='_blank'>
                    <strong>Abo beziehen</strong>
                </Nav.Link >
            )
        // return (
        //     <Nav.Link href="/Kaufen" className='not-mobile'>
        //         <Link className='link' to={'https://www.bautechnik.pro/Vereinigung/mitglieder_abo'} target='_blank'>
        //             <strong>Abo beziehen</strong>
        //         </Link>
        //     </Nav.Link >
        // )
    }

    return (
        <Navbar bg="light" data-bs-theme="light">
            <Container>
                <Navbar.Brand href="">
                    <Link to="https://www.bautechnik.pro" target='_blank'>
                        <img
                            src={obvLogo}
                            width="70"
                            height="70"
                            alt='obv-logo'
                        />
                    </Link>
                </Navbar.Brand>
                <Nav className="me-auto center">
                    <Nav.Link href="/publikationen" className='not-mobile'>
                        ÖBV Publikationen
                    </Nav.Link>
                    <Nav.Link href="/" className='not-mobile'>
                        Neuigkeiten
                    </Nav.Link>
                    {getNavItemKaufen()}
                    {dropDownMobile}
                </Nav>
                {loginInformation}
            </Container>
        </Navbar>
    )
}
export default Header