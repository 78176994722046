import React from 'react';
import { Page } from 'react-pdf';
import Col from 'react-bootstrap/Col';

function SinlgePage(props) {
  const { pageNumber, searchText } = props;

  const customTextRenderer = ({ str, itemIndex }) => {
    if (searchText.length > 2 && str.toUpperCase() === searchText.toUpperCase())
      return `<mark>${str}</mark>`
    if (searchText.length > 2 && str.toUpperCase().includes(searchText.toUpperCase())) {
      let index = str.toUpperCase().indexOf(searchText.toUpperCase())
      let returnStrA = str.substring(0, index)
      let returnStrB = str.substring(index + searchText.length + 1, str.length)
      return `${returnStrA}<mark>${searchText}</mark>${returnStrB}`
    }
    else
      return str
  }

  return (
    <Col>
      <Page
        className='pdf-page'
        pageNumber={pageNumber}
        renderAnnotationLayer={true}
        renderTextLayer={true}
        customTextRenderer={customTextRenderer} />
    </Col>
  )
}
export default SinlgePage