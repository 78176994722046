import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Layout from './Layout/Layout';
import PublikationListe from './Features/Publikationen/PublikationListe';
import Neuigkeiten from './Features/Misc/Neuigkeiten'
import Kontakt from './Features/Misc/Kontakt'
import Publikation from './Features/Publikationen/Publikation';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Features/Auth/Login';
import Register from './Features/Auth/Register';
import VerifyMsg from './Features/Auth/VerifyMsg';
import Verify from './Features/Auth/Verify';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {<Route path='/*' element={<Layout />}>
          <Route index element={<Neuigkeiten />} />
          <Route path='kontakt' element={<Kontakt />} />
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='bestaetigen/:id' element={<VerifyMsg />} />
          <Route path='2-faktor/:id' element={<Verify />} />
          <Route path='publikationen'>
            <Route index element={<PublikationListe />} />
            <Route path=':id' element={<Publikation showOutlet={true} />} />
            <Route path=':id/inhaltsverzeichnis' element={<Publikation urlPostFix={'content'} showOutlet={false} />} />
            <Route path=':id/mitarbeiterliste' element={<Publikation urlPostFix={'collaborators'} showOutlet={false} />} />
          </Route>
        </Route>}
      </Routes>
    </BrowserRouter>
  )
}
export default App;