import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router'
import axios from 'axios';
import { toast } from 'react-toastify';
import PDFDocument from './_Partials/PDFDocument';

function Publikation(props) {
    const { urlPostFix, showOutlet } = props

    const [loading, setLoading] = useState(true)
    const [publikation, setPublikation] = useState({})

    const { id } = useParams();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLoading(true)
        let url = process.env.REACT_APP_BACKEND_URL + `/documents/${id}`

        if (urlPostFix)
            url += `/${urlPostFix}`

        axios.get(url)
            .then(res => {
                setPublikation(res.data);
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error.toJSON());
                toast.error('Fehler beim Anzeigen der Publikation!')
            });
    }, [id])

    let content
    if (!loading) {
        content = (
            <PDFDocument fileContent={publikation.content}
                searchIndex={publikation.searchIndex}
                showOutlet={showOutlet} />
        )
    }

    return content
}

export default Publikation