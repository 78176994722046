import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import selectCategoryOptions from './SelectKategorien'
import { Star, StarFill, ListOl, PeopleFill } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

function PublikationListe() {
    const [publikationen, setPublikationen] = useState([])
    const [filteredPublikationen, setFilteredPublikationen] = useState([])
    const [filter, setFilter] = useState('')
    const [kategorie, setKategorie] = useState('')
    const [loading, setLoading] = useState(true)
    const [favoritenFilter, setFavoritenFilter] = useState(false)
    const [user, setUser] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [showSearchFilter, setShowSearchFilter] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [showSearchFilterDelayed, setShowSearchFilterDelayed] = useState(true)

    const asyncUseEffect = async () => {
        try {
            setLoading(true)
            let _user = JSON.parse(localStorage.getItem('user'))
            setUser(_user)

            let url = process.env.REACT_APP_BACKEND_URL + '/documents/byUser'

            if (_user?.User)
                url += "/" + _user.ID
            else
                url += "/unset"

            let response = await axios.get(url, { withCredentials: true })
            setPublikationen(response.data);
            setFilteredPublikationen(response.data);
            setLoading(false)
        } catch (err) {
            toast.error('Fehler beim Laden der Publikation!')
        }
    }

    useEffect(() => {
        asyncUseEffect()
    }, []);

    const getAusserkraftdatumWarning = (ausserkraftdatum) => {
        if (ausserkraftdatum)
            return (
                <img
                    className='publikation-hat-ausserkraftdatum'
                    alt='Publikation ist auser Kraft'
                    src={'img/icon_publikation_alt_5.svg'}
                    title='Achtung! Diese Publikation ist auser Kraft.'
                />
            )
    }

    let picturesDisplayed = 2
    const getImg = (filename) => {
        if (!filename)
            filename = 'Standard_Deckblatt.jpg'

        picturesDisplayed++

        if (picturesDisplayed < 20)
            return (
                <img className='publikation-image'
                    alt='Publikation'
                    src={`img/publikationen/${filename}`}
                />
            )
        else
            return (
                <img className='publikation-image'
                    loading='lazy'
                    alt='Publikation'
                    src={`img/publikationen/${filename}`}
                />
            )

    }

    const _toggleFavorDOcument = (idArtikel) => {
        let pubs = [...publikationen]
        let updatedPub = pubs.find(pub => pub.IDArtikel.toUpperCase() === idArtikel.toUpperCase())

        if (updatedPub.IsFavorit)
            updatedPub.IsFavorit = undefined
        else
            updatedPub.IsFavorit = 'test123'

        setPublikationen(pubs)
    }

    const toggleFavorDocument = (idArtikel) => {
        _toggleFavorDOcument(idArtikel)

        axios.post
            (
                process.env.REACT_APP_BACKEND_URL + `/documents/toggleFavor/${idArtikel}`,
                {
                    IDUser: user.ID
                }
            )
            .then(res => {
                toast.info(res.data.message)
                reloadFilters()
            })
            .catch(function (error) {
                _toggleFavorDOcument(idArtikel)
                toast.error(error.response.data.message)
            });
    }

    const getFavIcon = (pub) => {
        if (user?.User) {
            if (pub.IsFavorit)
                return (
                    <StarFill title='Als Favorit speichern'
                        className='publikation-icon starfill'
                        onClick={() => toggleFavorDocument(pub.IDArtikel)} />
                )
            else
                return (
                    <Star title='Als Favorit speichern'
                        className='publikation-icon'
                        onClick={() => toggleFavorDocument(pub.IDArtikel)} />
                )
        }
    }

    const getInhaltsverzeichnis = (pub) => {
        if (pub.Inhalt) return (
            <a href={`publikationen/${pub.IDArtikel}/inhaltsverzeichnis`}>
                <ListOl title='Inhaltsverzeichnis anzeigen' className='publikation-icon' />
            </a>
        )
    }

    const getMitarbeiter = (pub) => {
        if (pub.Mitarbeiter) return (
            <a href={`publikationen/${pub.IDArtikel}/mitarbeiterliste`}>
                <PeopleFill title='Mitarbeiter anzeigen' className='publikation-icon' />
            </a>
        )
    }

    const getImgNeu = (pub) => {
        if (pub.NeuBis && new Date(pub.NeuBis) > new Date())
            return (
                <img className='publikation-ist-neu'
                    alt='Publikation ist neu'
                    src={'img/icon_seminar_neu.svg'} />
            )
    }

    const logPublikationClick = (artikelID, userID) => {
        axios.post
            (
                process.env.REACT_APP_BACKEND_URL + `/documents/log`,
                {
                    artikelID: artikelID,
                    userID: userID
                }
            )
            .then(res => {
                console.log(res.data.message)
            })
            .catch(error => {
                console.log(error)
            });
    }

    const getPublikationLinkWithImage = (pub) => {
        if (user?.User)
            return (
                <a onClick={() => logPublikationClick(pub.IDArtikel, user.ID)}
                    onAuxClick={() => logPublikationClick(pub.IDArtikel, user.ID)}
                    href={`/publikationen/${pub.IDArtikel}`}>
                    {getImg(pub.Bild)}
                </a>
            )
        else return (
            <a onClick={() => logPublikationClick(pub.IDArtikel, '')}
                onAuxClick={() => logPublikationClick(pub.IDArtikel, '')}
                href={`https://www.bautechnik.pro/Shop/artikel?IDArtikel=${pub.IDArtikel}`}>
                {getImg(pub.Bild)}
            </a>
        )
    }

    let content
    content = (
        <ul>
            {filteredPublikationen.map(pub =>
                <div key={pub.IDArtikel}
                    className='publikation'>
                    <p className='publikation-ausgabedatum'>
                        {("0" + (new Date(pub.Ausgabedatum).getMonth() + 1)).slice(-2)}/
                        {new Date(pub.Ausgabedatum).getFullYear()}
                        {/* {<span className='small'> {pub.IDArtikel}</span>} */}
                    </p>
                    {getAusserkraftdatumWarning(pub.Ausserkraftdatum)}
                    {getPublikationLinkWithImage(pub)}
                    <div className='publikation-icons'>
                        {getFavIcon(pub)}
                        {getInhaltsverzeichnis(pub)}
                        {getMitarbeiter(pub)}
                    </div>
                    {getImgNeu(pub)}
                </div>
            )}
        </ul >
    )

    let spinner
    if (loading) {
        spinner = (
            <Spinner animation='border' role='status' />
        )
    }

    const includesCaseInsensitive = (searchIn, searchFor) => {
        if (!searchFor)
            return true;
        else
            return searchIn.toLowerCase().includes(searchFor.toLowerCase())
    }

    const filterPublikationen = (filterBezeichnung, filterKategorie, onlyFavorites) => {
        return (!filterBezeichnung && !filterKategorie && !onlyFavorites)
            ? publikationen
            : publikationen.filter(pub => {
                if (onlyFavorites) {
                    return (includesCaseInsensitive(pub.Bezeichnung, filterBezeichnung)
                        && includesCaseInsensitive(pub.Kategorien, filterKategorie) && pub.IsFavorit)
                } else {
                    return (includesCaseInsensitive(pub.Bezeichnung, filterBezeichnung)
                        && includesCaseInsensitive(pub.Kategorien, filterKategorie))
                }
            })
    }

    const onChangeFilter = (e) => {
        let _filteredPublikationen = filterPublikationen(e.target.value, kategorie, favoritenFilter)
        setFilteredPublikationen(_filteredPublikationen)
        setFilter(e.target.value)
    }

    const onChangeKategorie = (e) => {
        let _filteredPublikationen = filterPublikationen(filter, e.target.value, favoritenFilter)
        setFilteredPublikationen(_filteredPublikationen)
        setKategorie(e.target.value)
    }

    const onChangeOnlyFavorites = () => {
        let _filteredPublikationen = filterPublikationen(filter, kategorie, !favoritenFilter)
        setFilteredPublikationen(_filteredPublikationen)
        setFavoritenFilter(!favoritenFilter)
    }

    const reloadFilters = () => {
        let _filteredPublikationen = filterPublikationen(filter, kategorie, favoritenFilter)
        setFilteredPublikationen(_filteredPublikationen)
    }

    let selectKategorien
    selectKategorien = (
        <select disabled={!showSearchFilterDelayed} className='select-kategorie' onChange={onChangeKategorie}>
            {selectCategoryOptions.map(option =>
                <option key={option.value} value={option.value}>
                    {option.option}
                </option>
            )}
        </select>
    )

    let favoritenFilterContent
    if (user?.User) {
        favoritenFilterContent = (favoritenFilter) ? (
            <StarFill title='Alle Publikationen anzeigen'
                className='filter starfill not-mobile'
                // onClick={() => setFavoritenFilter(!favoritenFilter)} />
                onClick={() => onChangeOnlyFavorites()} />
        ) : (
            <Star title='Nur Favoriten anzeigen'
                className='filter not-mobile'
                // onClick={() => setFavoritenFilter(!favoritenFilter)} />
                onClick={() => onChangeOnlyFavorites()} />
        )
    }

    let favoritenFilterContentMobile
    if (user?.User) {
        favoritenFilterContentMobile = (favoritenFilter) ? (
            <StarFill title='Alle Publikationen anzeigen'
                className='filter starfill mobile star-mobile'
                // onClick={() => setFavoritenFilter(!favoritenFilter)} />
                onClick={() => onChangeOnlyFavorites()} />
        ) : (
            <Star title='Nur Favoriten anzeigen'
                className='filter mobile star-mobile'
                // onClick={() => setFavoritenFilter(!favoritenFilter)} />
                onClick={() => onChangeOnlyFavorites()} />
        )
    }

    let searchFilterProps
    searchFilterProps = (showSearchFilter) ? { opacity: '1' } : {}

    // const toggleFilter = () => {
    //     let delay = (showSearchFilter) ? 1000 : 0

    //     setShowSearchFilter(!showSearchFilter)
    //     setTimeout(() => {
    //         setShowSearchFilterDelayed(!showSearchFilterDelayed)
    //     }, delay);
    // }

    let placeholder
    placeholder = (window.innerWidth > 1000) ? 'Nach Publikationstiteln suchen' : 'Titel durchsuchen'

    return (
        <div>
            <div className='header-area'>
                <h1 className='heading'>ÖBV Publikationen</h1>
                {favoritenFilterContentMobile}
                <div className='filter-area'>
                    <div className='search-filter-content'
                        style={searchFilterProps}>
                        {selectKategorien}
                        <input className='search-bar' disabled={!showSearchFilterDelayed} value={filter} onChange={onChangeFilter} placeholder={placeholder} />
                    </div>
                    {/* <Search className='filter' onClick={() => toggleFilter()} /> */}
                    {favoritenFilterContent}
                </div>
            </div>
            <div className='publikationenListe'>
                {content}
                {spinner}
            </div>
        </div>
    )
}
export default PublikationListe